import React from 'react';
import { Button, TextInput, Typography } from '@eucalyptusvc/design-system';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import {
  BaseDiscountCodeFormProps,
  calculateDiscountedPrice,
  getDiscountAppliedText,
  useDiscountCodeForm,
} from '@customer-frontend/order';
import { ReactComponent as CircleTick } from '../assets/circle-tick-current-color.svg';
import { ReactComponent as ShoppingTag } from '../assets/shopping-tag.svg';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

interface DiscountCodeFormProps extends BaseDiscountCodeFormProps {
  onRemoveDiscount: () => void;
  loading?: boolean;
  subtotal: number;
  defaultCodeToApply?: string | null;
}

export const RemovableDiscountCodeForm = ({
  code,
  stage,
  onRemoveDiscount,
  onChange,
  loading = false,
  products,
  subtotal,
  skipProductValidation,
  defaultCodeToApply,
}: DiscountCodeFormProps): React.ReactElement => {
  // Don't override an existing discount code on the treatment
  let defaultCode: string | null | undefined = null;
  if (!code) {
    defaultCode = defaultCodeToApply;
  }

  const {
    handleInputPress,
    handleDiscountChange,
    errorMessage,
    handleDiscountApply,
    setInputText,
    setErrorMessage,
    inputText,
    isRecurringDiscount,
  } = useDiscountCodeForm({
    code,
    stage,
    onChange,
    products,
    skipProductValidation,
    defaultCode,
  });

  const handleOnClickRemove = (): void => {
    onRemoveDiscount();
    setInputText('');
    setErrorMessage(null);
  };

  const { amountSaved } = React.useMemo(
    () =>
      calculateDiscountedPrice({
        subtotal,
        discount: code,
        products,
      }),
    [code, products, subtotal],
  );

  return (
    <div className="space-y-2">
      <div className="md:max-w-xs ">
        {!code ? (
          <div className="flex items-end space-x-4">
            <TextInput
              name="discountCode"
              label="Discount Code"
              type="text"
              defaultValue={inputText}
              value={inputText}
              onKeyPress={handleInputPress}
              onChange={handleDiscountChange}
              disabled={!!code}
              className={clsx('font-mono tracking-widest', {
                'bg-gray-100 text-gray-500': !!code,
              })}
            />
            <div className="w-24 mb-1">
              <Button onClick={handleDiscountApply} isLoading={loading}>
                <FormattedMessage
                  defaultMessage="Apply"
                  description="Button text to apply a discount code"
                />
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-start items-center space-x-4">
            <div className="flex flex-row items-center space-x-2">
              <ShoppingTag />
              <Typography size="medium-paragraph" isBold>
                {code.code}
              </Typography>
            </div>
            <Button
              size="sm"
              level="secondary"
              onClick={handleOnClickRemove}
              isLoading={loading}
            >
              <FormattedMessage
                defaultMessage="Remove"
                description="Button to remove an applied discount code"
              />
            </Button>
          </div>
        )}

        {errorMessage && (
          <Typography
            size="small-text"
            isBold
            color={sharedColors.status.error[500]}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
      {code && (
        <div className="flex flex-row items-center">
          <CircleTick
            className="fill-current overflow-visible mr-2"
            color={sharedColors.status.success[500]}
          />
          <Typography
            size="small-text"
            isBold
            color={sharedColors.status.success[500]}
          >
            {getDiscountAppliedText({ code, isRecurringDiscount, amountSaved })}
          </Typography>
        </div>
      )}
    </div>
  );
};
