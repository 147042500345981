import { SubscriptionPlanInterval } from '@customer-frontend/graphql-types';
import { Brand } from '@customer-frontend/types';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import {
  COMMON_GUARANTEES,
  T_SUPPORT_GUARANTEES,
  WEIGHT_SHAKE_GUARANTEES,
} from '../constants';
import { GuaranteeListItem, SubscriptionPlanProduct } from '../types';

const getSubscriptionPlanIntervalString = (
  interval: SubscriptionPlanInterval,
): string | null => {
  switch (interval) {
    case 'DAYS':
      return 'day';
    case 'MONTHS':
      return 'month';
    default:
      return null;
  }
};

export const formatSubscriptionCadence = (
  interval: SubscriptionPlanInterval,
  intervalCount: number,
): string | null => {
  const formattedInterval = getSubscriptionPlanIntervalString(interval);

  if (!formattedInterval) {
    return null;
  }

  return `${intervalCount > 1 ? intervalCount + ' ' : ''}${formattedInterval}${
    intervalCount > 1 ? 's' : ''
  }`;
};

export const getBaseTextColorClassName = (brand: Brand): string => {
  switch (brand) {
    case 'pilot':
      return sharedColors.neutral[700];
    default:
      return '';
  }
};

export const getSubscriptionProducts = (subscriptionPlan: {
  id: string;
  name: string;
  interval: SubscriptionPlanInterval;
  intervalCount: number;
  price: number;
  photo: {
    url: string;
  };
}): SubscriptionPlanProduct[] => {
  return [
    {
      name: subscriptionPlan.name,
      photoUrl: subscriptionPlan.photo.url,
      price: subscriptionPlan.price,
    },
  ];
};

export const getSubtotal = (products: SubscriptionPlanProduct[]): number => {
  return products.reduce((sum, product) => product.price + sum, 0);
};

export const getGuaranteeListItemsFromPlanName = (
  name: string,
): GuaranteeListItem[] => {
  let planSpecificList: GuaranteeListItem[] = [];
  const upperCaseName = name.toUpperCase();

  switch (true) {
    case upperCaseName.includes('SHAKE'):
      planSpecificList = WEIGHT_SHAKE_GUARANTEES;
      break;
    case upperCaseName.includes('TESTOSTERONE'):
      planSpecificList = T_SUPPORT_GUARANTEES;
      break;
    default:
      break;
  }

  return [...planSpecificList, ...COMMON_GUARANTEES];
};
